import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress, LogoHolder } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import canRead from "../../canRead";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import HooksPage from "../web-hook/HooksPage";
import FunctionPage from "../web-hook/FunctionPage";
import SchemaPage from "../schema/SchemaPage";
import menus from "./menus.js";
import Menu from "../../components/Menu";
import UserPage from "../users/UserPage";
import MemberPage from "../member-list/MemberPage.js";
import UserFormPage from "../users/UserFormPage.js";
import DashboardAdminPage from "../dashboards/dashboard-admin/DashboardAdminPage.js";
import NotificationPage from "../notification/NotificationPage.js";
import ProfilePage from "../profile/ProfilePage.js";
import AttendancePage from "../attendance/AttendancePage.js";
import EventPage from "../event/EventPage.js";
import QRPageDisplay from "../qr/QRPageDisplay.js";
import EventFormPage from "../event/EventFormPage.js";
import EventListPage from "../event/EventListPage.js";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route, item);
  }

  filterAccess(menus, roles) {
    return menus.filter((menu) => {
      if (Array.isArray(menu.route)) {
        menu.route = this.filterAccess(menu.route, roles);
      }
      if (!menu.access) {
        return true;
      }
      if (roles.some((role) => menu.access.includes(role?.id))) {
        return true;
      }
      return false;
    });
  }

  render() {
    const user = this.getCurrentUser();
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();
    if (user === undefined || schemas === undefined) {
      return <Progress />;
    }
    const settings = [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-check",
      },
      {
        name: "Schema",
        route: "/schema",
        icon: "bi bi-filetype-json",
      },
      // {
      //     name: "Notification",
      //     route: "/notification",
      //     icon: "bi bi-bell"
      // },
    ];

    const hook = [
      {
        name: "Hooks",
        route: "/hooks",
        icon: "bi bi-person-check",
      },
      {
        name: "Function",
        route: "/function",
        icon: "bi bi-person-check",
      },
    ];

    const setting = {
      name: "Settings",
      icon: "bi bi-sliders",
      route: settings,
    };

    const hooksMenu = {
      name: "WebHook",
      icon: "bi bi-sliders",
      route: hook,
    };

    const menuss = menus;
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body p-0"
                style={{ backgroundColor: "white" }}
              >
                <nav className="">
                  <div
                    className="text-center p-4"
                    style={{
                      backgroundImage: 'url("/conq.png")',
                      // backgroundColor: "#416bc1",
                      backgroundPosition: "center top",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <LogoHolder
                      className="bg-white"
                      textClassName="text-dark"
                      logo={user.picture}
                      name={user.username}
                    />
                    <p className="text-white mt-3">
                      {user.name || user.username}
                    </p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <div className="m-4">
                    <Menu
                      onClickItem={this.onClickMenu.bind(this)}
                      menus={this.filterAccess(menuss, roles)}
                    />
                  </div>
                </nav>
              </div>
              <div className="bg-white pb-3 ps-3">
                <button
                  className="nav-link text-white btn btn-link"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power text-dark"></i>
                  <span className="ms-2 fw-bold small text-dark">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            <Route exact path={"/"} element={<DashboardPage />} />
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={"/collection/dashboard"}*/}
            {/*  element={<DashboardPage />}*/}
            {/*/>*/}
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form/"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />
            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/schema"} element={<SchemaPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route path={"/hooks"} element={<HooksPage />} />
            <Route path={"/function"} element={<FunctionPage />} />
            <Route path={"/users"} element={<UserPage />} />
            <Route path={"/user-form"} element={<UserFormPage />} />
            <Route path={"/user-form/:id"} element={<UserFormPage />} />
            <Route path={"/dashboard-admin"} element={<DashboardAdminPage />} />
            <Route path={"/notification"} element={<NotificationPage />} />
            <Route path={"/events"} element={<EventPage />} />
            <Route
              exact
              path={"/collection/members"}
              element={<MemberPage />}
            />
            <Route exact path={"/attendance"} element={<AttendancePage />} />
            <Route exact path={"/profile/:id"} element={<ProfilePage />} />
            <Route
              path="/qr-validation-display/:firstName/:middleName/:lastName/:email/:eventName/:eventDate"
              element={<QRPageDisplay />}
            />
            <Route exact path={"/create-event"} element={<EventFormPage />} />
            <Route path="/events-lists" element={<EventListPage />} />
            <Route
              exact
              path={"/create-event/:id"}
              element={<EventFormPage />}
            />
            <Route element={<NotFoundPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
