import React from "react";
// import Checkbox from "../Checkbox";
// import toDisplayCase from "../toDisplayCase";
import { Checkbox, toDisplayCase } from "nq-component";

function Head({selectable, fields, excludeFields, actionsList, selected, onSelectAll, objects}) {
    return (
        <tr>
            {selectable && (
                <th style={{ backgroundColor: "#043769", color: "white" }}>
                    <Checkbox
                    
                        className="align-middle"
                        id="check_all"
                        checked={objects.length === selected.length && objects.length !== 0}
                        onChange={checked => onSelectAll(checked)}
                    />
                </th>
            )}
            {
                Object.keys(fields).map((field, index) => {
                    const {type, ...options} = fields[field];
                    if (excludeFields.includes(field)) return null;
                    const label = options.label || toDisplayCase(field);
                    return (
                        <th key={field}
                            className="fs-xs align-middle text-nowrap" style={{ backgroundColor: "#043769", color: "white" }}>{label} </th>
                    );
                })}
            {actionsList.length > 0 && (
                <>
                    <th>
                        <div style={{width: "50px"}}></div>
                    </th>
                    <th
                        className="fs-xs align-middle text-nowrap"
                        colSpan={actionsList.length}>ACTIONS
                    </th>
                </>
            )}
        </tr>
    )
}

export default Head;