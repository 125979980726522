import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { deleteObjectUseCase } from "../../usecases/object";

class UserPresenter extends BaseListPresenter {
  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/user-form");
  }

  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = { createdBy: user.id };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  onClickProfile(index) {
    const object = this.objects[index];
    console.log("index", object);
    this.view.navigateTo("/profile" + "/" + object.id);
  }

  async onClickDelete(index) {
    dialog.close();
    const object = this.objects[index].id;
    await deleteObjectUseCase().execute("members", object);
    this.objects.splice(index, 1);
    this.view.setObjects(this.objects);
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/user-form/" + object.id);
  }
}

export default UserPresenter;
