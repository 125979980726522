import BaseListPresenter from "../../base/BaseListPresenter";

class ProfilePresenter extends BaseListPresenter {
  onClickAdd() {
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/user-form");
  }

  init() {
    const user = this.view.getCurrentUser();
    const params = this.view.getParams();
    this.limit = 20;
    this.where = { id: params.id };
    this.search = {};
    this.filter = {};
    this.include = ["All", "cellLeader", "invitedBy", "netLeaders"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  //   onClickProfile(index) {
  //     const object = this.object[index];
  //     this.view.navigateTo("/profile" + "/" + object.id);
  //   }
}

export default ProfilePresenter;
