import BaseListPresenter from "../../../base/BaseListPresenter";
import { findObjectUseCase } from "../../../usecases/object";

class DashboardAdminPresenter extends BaseListPresenter {
  init() {
    this.limit = 20;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
    this.userAgent = [];
    this.newEndorseLetter = [];
    this.filterDate = {};
    this.filterLocation = "";
  }

  componentDidMount() {
    this.init();
    return this.getObjects();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }
  async findObjects() {
    const collection = this.view.getCollectionName();
    const skip = (this.current - 1) * this.limit;
    const query = this.createQuery();

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute("members", query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      console.log("hagagagga", this.objects);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  calculateAllMembersInfo() {
    const allMembers = this.objects;

    return {
      value: allMembers?.length,
      percentage: 0,
    };
  }

  calculateP12Info() {
    const { p12 } = this.getMembers();

    return {
      value: this.userAgent?.filter((a) => a.is_p12 === true)?.length,
      percentage: 0,
    };
  }

  calculate144Info() {
    const { m144 } = this.getMembers();
    return {
      value: this.userAgent?.filter((a) => a.is_144 === true)?.length,
      percentage: 0,
    };
  }

  calculateYoungInfo() {
    const { young } = this.getMembers();
    return {
      value: this.userAgent?.filter((a) => a.is_young === true)?.length,
      percentage: 0,
    };
  }

  calculateEntrepreneurInfo() {
    const { entrepreneur } = this.getMembers();
    return {
      value: this.userAgent?.filter((a) => a.is_entre === true)?.length,
      percentage: 0,
    };
  }

  calculateYouthInfo() {
    const { youth } = this.getMembers();
    return {
      value: this.userAgent?.filter((a) => a.is_youth === true)?.length,
      percentage: 0,
    };
  }

  async getMembers() {
    const query = {
      include: ["All"],
    };
    const userAgentActive = await findObjectUseCase().execute("members", query);
    this.userAgent = userAgentActive;
    console.log("conquerors", this.userAgent);
  }

  calculateActiveInfo() {
    const { agentActive } = this.calculateActive();

    return {
      value: this.userAgent?.length,
      percentage: 0,
    };
  }

  calculatePendingInfo() {
    return {
      value: this.objects
        ?.filter((o) => o.statusQA === "Done")
        .map((o) => o.statusQA)?.length,
      percentage: 0,
    };
  }

  async calculateNew() {
    const query = {
      where: {
        status: "For Dispatch",
      },
    };
    const newEndorse = await findObjectUseCase().execute("endorsement", query);
    this.newEndorseLetter = newEndorse;
  }

  calculateNewInfo() {
    const { agentActive } = this.calculateNew();
    return {
      value: this.newEndorseLetter?.length,
      percentage: 0,
    };
  }

  filterSubmit(where) {
    const locationId = where.locationP?.id;
    this.reset();
    this.filterLocation = locationId;
    this.getObjects();
  }
}

export default DashboardAdminPresenter;
