import React, { Component } from "react";
import { findObjectUseCase, upsertUseCase } from "../../usecases/object";
import BasePage from "../../base/BasePage";
import withRouter from "../../withRouter";
import { dialog } from "nq-component";

class QRPageDisplay extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      qrShow: [],
      isRegistered: false,
      eventDate: "",
      isRegisteredDate: false,
      close: false,
    };
  }

  componentDidMount() {
    const paramsId = this.getParams();
    if (paramsId.eventDate) {
      let eventDateConvert = new Date(paramsId.eventDate);

      const eventDate = eventDateConvert.toISOString().slice(0, 10);
      const currentDate = new Date().toISOString().slice(0, 10);
      const user = this.getCurrentUser();
      console.log("uuser", user);
      const userRoles = user.roles.map((role) => role.id);

      console.log("ed", userRoles);

      if (
        eventDate === currentDate &&
        (userRoles.includes("pastor") ||
          userRoles.includes("p12") ||
          userRoles.includes("multimedia"))
      ) {
        console.log("nc one");
        this.setState({ isRegisteredDate: true });
        this.saveData();
      } else {
        this.setState({ isRegisteredDate: false });
        if (!userRoles.length) {
          dialog.fire({
            html: (
              <div className="p-3 px-lg-5 py-lg-4 text-center">
                <i
                  class="bi bi-exclamation-circle-fill"
                  style={{
                    fontSize: "100px",
                    color: "red",
                  }}
                ></i>
                <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
                  You are not eligible to scan the QR code.
                </h4>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.closeDialog()}
                  >
                    close
                  </button>
                </div>
              </div>
            ),
            footer: false,
          });
        }
      }
    } else {
      console.log("Event date is not set");
    }
  }

  closeDialog() {
    this.setState({ close: true });
    dialog.close();
  }

  async saveData() {
    const paramsId = this.getParams();
    this.setState({ eventDate: paramsId.eventDate });
    const check = await findObjectUseCase().execute("participants");
    console.log("check", check);

    if (check.some((participants) => participants.email === paramsId.email)) {
      this.setState({ isRegistered: true });
      return;
    } else {
      await upsertUseCase().execute("participants", paramsId);
      this.setState({ isRegistered: false });
    }
  }

  render() {
    return (
      <div>
        <>
          <div className="vh-100" style={{ backgroundColor: "#FAFAFA" }}>
            <div className="d-flex h-100">
              <div
                className="container p-3 p-lg-5"
                style={{ marginTop: "150px" }}
              >
                {this.state.close ? null : (
                  <div className="bg-white shadow rounded p-3 p-lg-5">
                    <div className="">
                      {this.state.isRegisteredDate ? (
                        <div className="p-3 px-lg-5 py-lg-4 text-center">
                          <i
                            class={`bi bi-${
                              this.state.isRegistered
                                ? "bi bi-x-circle"
                                : "person-fill-check"
                            }`}
                            style={{
                              fontSize: "100px",
                              color: this.state.isRegistered
                                ? "red"
                                : "#83c24b",
                            }}
                          ></i>
                          <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
                            {this.state.isRegistered
                              ? "You are already Registered!"
                              : "Successfully Registered!"}
                          </h4>
                        </div>
                      ) : (
                        <div className="p-3 px-lg-5 py-lg-4 text-center">
                          <i
                            class="bi bi-exclamation-circle-fill"
                            style={{
                              fontSize: "100px",
                              color: "red",
                            }}
                          ></i>
                          <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
                            QR Code Registration is not yet open.
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }
}

export default withRouter(QRPageDisplay);
