import React from "react";
import schemaDashboard from "./schemaDashboard.json";
import DashboardAdminPresenter from "./DashboardAdminPresenter";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import {
  countObjectUseCase,
  findObjectUseCase,
} from "../../../usecases/object";
import NavBar2 from "../../../components/NavBar2";
import Count from "./components/Count";

import InputFactory from "../../../components/InputFactory";
import DialogTable from "../../../components/DialogTable";
import { dialog } from "nq-component";
import NavBar from "../../../components/navbar";

class DashboardAdminPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DashboardAdminPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      range: "",
      store: [],
      storess: "",
    };
  }

  getCollectionName() {
    return "members";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "All Members":
        return this.presenter.calculateAllMembersInfo();
      case "P12":
        return this.presenter.calculateP12Info();
      case "144":
        return this.presenter.calculate144Info();
      case "Young Pro":
        return this.presenter.calculateYoungInfo();
      case "Youth":
        return this.presenter.calculateYouthInfo();
      case "Entrepreneur":
        return this.presenter.calculateEntrepreneurInfo();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
  }

  filterSubmit(where) {
    this.presenter.filterSubmit(where);
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.filterSubmit(where);
  }

  onCLickWidget(object) {
    const schema = this.getSchema(object.collection);
    console.log("object", object);
    dialog.fire({
      html: (
        <DialogTable
          title={object.collection}
          where={object.where}
          schema={schema}
          onCancel={() => dialog.close()}
        />
      ),
      footer: false,
    });
  }

  render() {
    const objects = this.state.objects;
    const schema = this.getSchema(this.getCollectionName());

    console.log("translate", schema);

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fw-bold mt-3 text-capitalize">Dashboard</h1>
            </div>
            <div className="row">
              <div className="col-sm-4 mb-2"></div>
              <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];

                  return (
                    <div className="mb-2">
                      <InputFactory
                        key={field}
                        className="ms-1"
                        type={type}
                        field={field}
                        where={{}}
                        onChange={this.onChangeFilter.bind(this, type)}
                        {...options}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row mt-1 g-3">
              {schemaDashboard.map((object, index) => {
                const { value, percentage } = this.getValueForDashboardObject(
                  object,
                  objects
                );

                const navigationTarget = object.navigationTarget;
                return (
                  <div key={index} className="col-6 col-md-3">
                    <Count
                      collection={object.collection}
                      icon={object.icon}
                      where={object.where}
                      val={value}
                      label={object.label}
                      labelAction={object.labelAction}
                      barangayValue={this.state.barangayValue}
                      object={object}
                      percentage={percentage}
                      bg={object.bg}
                      onClick={this.onCLickWidget.bind(this, object)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DashboardAdminPage);
