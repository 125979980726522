// import React, { createContext, useState, useContext } from "react";

// const NotificationContext = createContext();

// export function NotificationProvider({ children }) {
//   const [unseenCount, setUnseenCount] = useState(0);

//   return (
//     <NotificationContext.Provider value={{ unseenCount, setUnseenCount }}>
//       {children}
//     </NotificationContext.Provider>
//   );
// }

// export function useNotification() {
//   return useContext(NotificationContext);
// }

import React, { createContext, useState, useContext, useEffect } from "react";
import { findObjectUseCase } from "./usecases/object";

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [unseenCount, setUnseenCount] = useState(0);

  // Fetch unseen notifications when the app initializes
  useEffect(() => {
    async function fetchUnseenNotifications() {
      try {
        const collection = "notifications";
        const query = { where: { isView: false } }; // Adjust query to match your backend
        const objects = await findObjectUseCase().execute(collection, query);
        setUnseenCount(objects.length);
        console.log("Initialized unseen count:", objects.length);
      } catch (error) {
        console.error("Failed to fetch unseen notifications:", error);
      }
    }

    fetchUnseenNotifications();
  }, []);

  return (
    <NotificationContext.Provider value={{ unseenCount, setUnseenCount }}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useContext(NotificationContext);
}
