import BaseListPresenter from "../../base/BaseListPresenter";
import { findObjectUseCase } from "../../usecases/object";

class EventPresenter extends BaseListPresenter {
  init() {
    const user = this.view.getCurrentUser();
    this.limit = 20;
    this.where = {
      clientName: user.name,
    };
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.filterDate = {};
    this.filterStatus = "";
    this.reset();
  }

  async getObjects() {
    this.reset();
    await this.countObjects();
    await this.findObjects();
    await this.findReport();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        status: this.filterStatus ? this.filterStatus : undefined,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const query = this.createQuery();
    const queryMember = {
      where: {
        "user.id": user.id,
      },
    };

    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      const member = await this.findObjectUseCase.execute(
        "members",
        queryMember
      );
      console.log("memberssss", member);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.view.setMembers(member);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  onClickAdd() {
    this.view.navigateTo("/collection/events/form");
  }

  onClickItem(index) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form/" + object.id);
  }

  onClickView(index, field) {
    const object = this.objects[index];
    this.view.navigateTo("/endorsement-form-view/" + object.id);
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  filterSubmit(where) {
    const statusName = where.statusFilter?.id;
    this.reset();
    this.filterStatus = statusName;
    this.getObjects();
  }

  onClickDownload(index) {
    this.view.exportPDF();
  }

  async findReport(index) {
    const query = {
      include: ["all"],
    };

    const registrations = await findObjectUseCase().execute(
      "registrations",
      query
    );
    console.log("registrations", registrations);

    this.view.setState({ registrations });
  }

  onClickEdit(index) {
    const object = this.objects[index];
    this.view.navigateTo(`/endorsement-form/${object.id}`);
  }
}

export default EventPresenter;
