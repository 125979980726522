import React from "react";
import { InputJson } from "nq-component";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory from "../../components/FormFactory";
import EventFormPresenter from "./EventFormPresenter";

class EventFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = { object: {}, advanced: false };
    this.presenter = new EventFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  getCollectionName() {
    return "events";
  }

  onClickAdvance() {
    this.setState({ advanced: !this.state.advanced });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  render() {
    const object = this.state.object;
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">
                <i
                  class="bi bi-chevron-compact-left"
                  onClick={() => this.navigateBack()}
                ></i>
                {label + (schema.label || this.getCollectionName())}
              </h1>
              <div
                className="text-white fw-bold"
                style={{
                  backgroundColor: "#517EC1",
                  borderRadius: "5px 5px 0 0",
                }}
              >
                <p
                  className="d-flex align-content-center"
                  style={{
                    padding: "13px",
                    margin: "0px",
                    fontSize: "14px",
                  }}
                >
                  {object.id
                    ? "Edit Event Information Form"
                    : "New Event Information Form"}
                </p>
              </div>
              <div className=" bg-white shadow  p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <FormFactory
                        schema={schema}
                        object={object}
                        onChange={this.onChange.bind(this)}
                      />
                    )}
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn fs-sm me-3 text-white"
                      style={{ backgroundColor: "#517EC1" }}
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EventFormPage);
