import React from "react";
import { dialog, Button } from "nq-component";

import {
  addSchemaUseCase,
  updateSchemaUseCase,
  deleteSchemaUseCase,
} from "../../usecases/schema/usecases";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import { Progress, InfiniteScroll } from "nq-component";
import withRouter from "../../withRouter";
import Search from "../../components/Search";
import BaseListPage from "../../base/BaseListPage";
import NavBar from "../../components/navbar";
import InputFactory from "../../components/InputFactory";
import Table from "../../components/Table";
import Table1 from "../../components/Table1";
import EventListPresenter from "./EventListPresenter";
import DialogDelete from "./components/DialogDelete";

class EventListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new EventListPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase(),
      addSchemaUseCase(),
      updateSchemaUseCase(),
      deleteSchemaUseCase()
    );
  }
  componentDidUpdate(prevProps, prevState) {
    this.presenter.componentDidUpdate(prevProps, prevState);
  }

  getCollectionName() {
    return "events";
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.searchSubmit(where);
  }

  onClickItemEdit(index) {
    this.presenter.onClickItem(index);
  }

  onClickItemDelete(index) {
    this.presenter.onClickDelete(index);
  }

  onClickDelete(index) {
    dialog.fire({
      html: (
        <DialogDelete
          onClickClose={() => dialog.close()}
          onClickItemDelete={() => this.onClickItemDelete(index)}
        />
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    return (
      <div className="m-3">
        <div className="d-flex">
          <div
            style={{
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              border: "1px solid #ccc", // optional for a placeholder border
            }}
          >
            {object["picture"] ? (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={object["picture"]}
                alt="object"
              />
            ) : (
              <i
                className="bi bi-card-image"
                style={{
                  fontSize: "48px", // Adjust icon size as needed
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#aaa", // optional for placeholder color
                }}
              ></i>
            )}
          </div>

          <div className="ms-2">
            <div className="d-flex">
              <h6 className="fw-bold me-1">Event Name:</h6>
              <h6>{object.eventName}</h6>
            </div>

            <div className="d-flex">
              <h6 className="fw-bold me-1">Event Created:</h6>
              <h6>
                {" "}
                {new Date(object.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </h6>
            </div>
            <div className="d-flex">
              <h6 className="fw-bold me-1">Event Date:</h6>
              <h6>
                {" "}
                {new Date(object.eventDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </h6>
            </div>
            <div className="d-flex">
              <h6 className="fw-bold me-1">Location:</h6>
              <h6>{object.location}</h6>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <Button
            className="btn shadow-sm text-white"
            onClick={() => this.onClickItemEdit(index)}
            style={{
              width: "95px",
              height: "50px",
              borderRadius: "5px",
              backgroundColor: "#416BC1",
            }}
          >
            {" "}
            <i class="bi bi-pencil-square"></i> Edit
          </Button>
          <Button
            className="btn shadow-sm text-white ms-2"
            onClick={() => this.onClickDelete(index)}
            style={{
              width: "95px",
              height: "50px",
              borderRadius: "5px",
              backgroundColor: "#CF3B3B",
            }}
          >
            <i class="bi bi-trash3-fill"></i>
            <span> Delete</span>
          </Button>
        </div>
      </div>
    );
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const title = this.getArgument().name;
    const { objects, selected, count, progress, loading } = this.state;

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {/* {schema.label || title} */}
                  Events
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <Table1
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  ["acl", "picture"]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
                loading={loading}
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn shadow-sm"
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#416BC1",
            }}
          >
            <i className="bi bi-plus-lg text-white" />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(EventListPage);
