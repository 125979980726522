// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './scss/index.scss';
// import App from './App';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <App/>
// );

import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/index.scss";
import App from "./App";
import { NotificationProvider } from "./NotificationContext"; // Import NotificationProvider

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NotificationProvider>
    <App />
  </NotificationProvider>
);
