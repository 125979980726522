import React from "react";
import classNames from "../../classNames";

const defaultProps = {
  className: "bg-white",
  icon: "bi bi-people",
  value: "0",
  label: "TOTAL",
  labelAction: "VIEW",
};

function DashboardCardAdmin({
  className,
  icon,
  label,
  value,
  labelAction,
  onClick,
  percentage,
  bg,
}) {
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    let start = 0;
    const end = parseInt(value);
    const duration = 1000; // Animation duration in milliseconds
    const increment = Math.ceil(end / (duration / 10));
    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        setCount(end);
        clearInterval(timer);
      } else {
        setCount(start);
      }
    }, 10);
    return () => {
      clearInterval(timer); // Clean up the timer when component unmounts
    };
  }, [value]);

  return (
    <div
      className={classNames("shadow-sm p-3 p-lg-4 rounded")}
      style={{ backgroundColor: bg }}
    >
      <div className="d-flex justify-content-between">
        <span className="fs-2">
          <i className={icon} style={{ color: "white" }}></i>
        </span>
        <h3 style={{ color: "white" }}>{count.toLocaleString()}</h3>
      </div>
      <div className="text-end text-white">
        <p className="m-0 text-truncate">{label}</p>
      </div>

      <hr
        className="
      text-white"
      />
      {labelAction === "VIEW" && (
        <button
          onClick={onClick}
          className="btn btn-link p-0 w-100 d-flex justify-content-between text-uppercase text-truncate text-white"
        >
          {labelAction}
          <i className="bi bi-arrow-right text-white"></i>
        </button>
      )}
    </div>
  );
}

DashboardCardAdmin.defaultProps = defaultProps;
export default DashboardCardAdmin;
