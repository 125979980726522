import browseFile from "../../browseFile";
import csvToJson from "../../csvToJson";
import unflatten from "../../unflatten";
import jsonToObject from "../../jsonToObject";
import BaseListPresenter from "../../base/BaseListPresenter";
import saveAs from "../../saveAs";
import { deleteObjectUseCase, upsertUseCase } from "../../usecases/object";
import { dialog } from "nq-component";

class MemberPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.upsertUseCase = upsertUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.deleteSchemaUseCase = deleteSchemaUseCase;
  }

  componentDidUpdate(prevProps) {
    const query = this.view.getQuery();
    if (query.where && prevProps.query.where !== query.where) {
      this.reset();
      this.where = JSON.parse(query.where);
      this.getObjects();
    }
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.current = 1;
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    const query = this.view.getQuery();
    console.log("qqq", query);
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();

    if (query.where) {
      this.where = JSON.parse(query.where);
    }
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/collection/" + collection + "/form/" + object.id);
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      console.log("get", this.objects);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  async onClickDelete(index) {
    const object = this.objects[index].id;
    await deleteObjectUseCase().execute("members", object);
    this.objects.splice(index, 1);
    this.view.setObjects(this.objects);
  }

  async onClickActive(index) {
    dialog.close();
    const object = this.objects[index];

    object.status =
      object.status === "Pending"
        ? "Approved"
        : object.status === "Approved"
        ? "Disapproved"
        : "Approved";
    const collection = this.view.getCollectionName();
    const userObject = object.user;
    console.log("user", userObject);
    console.log("objectActive", object);
    await upsertUseCase().execute(collection, object);
    // await upsertUseCase().execute("users", userObject);
    this.objects[index] = object;

    this.view.setObjects(this.objects);
    this.reset();
    this.getObjects();
    dialog.fire({
      html: (
        <div className="m-3">
          <div className="text-center mb-3">
            <h5>
              Member successfully approved. The member you approved will be
              removed and moved to the verified member sub-module.
            </h5>
          </div>
          <div className="text-center">
            <button
              className="btn text-white"
              style={{ backgroundColor: "#517ec1" }}
              onClick={() => dialog.close()}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }
}

export default MemberPresenter;
