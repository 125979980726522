// import React, { useState } from "react";
// import QRCode from "react-qr-code";

// function QRPage({ onCancel, qrObject }) {
//   console.log("qr scan", qrObject);
//   const [inputValue, setInputValue] = useState("");
//   const url = "https://event.strikeas1.com/qr-validation-display/";
//   const handleInputChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   return (
//     <>
//       <div className="vh-100" style={{ backgroundColor: "#FAFAFA" }}>
//         {/* <NavBar /> */}
//         <div className="d-flex h-100">
//           <div className="m-auto container p-3 p-lg-5">
//             <div className="bg-white shadow rounded p-3 p-lg-5">
//               <div className="">
//                 <div className="p-3 px-lg-5 py-lg-4">
//                   <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
//                     Registration Successful!
//                   </h4>
//                   <p
//                     style={{
//                       opacity: "0.5",
//                       marginBottom: "20px",
//                       fontSize: "14px",
//                     }}
//                   >
//                     Here is your unique QR code that will be scanned for your
//                     attendance. Kindly screenshot or save this page. Thank you!
//                   </p>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       marginTop: "50px",
//                     }}
//                   >
//                     {/* <h1>QR Code Generator</h1>
//                                         <input
//                                             type="text"
//                                             value={inputValue}
//                                             onChange={handleInputChange}
//                                             placeholder="Enter text to generate QR code"
//                                             style={{ padding: '10px', marginBottom: '20px', width: '300px', fontSize: '16px' }}
//                                         /> */}
//                     <div style={{ padding: "10px", background: "white" }}>
//                       <QRCode value={url} size={256} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default QRPage;

import React, { Component } from "react";
import QRCode from "react-qr-code";
import NavBar from "../../../components/navbar";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import { Button } from "nq-component";
import { findObjectUseCase } from "../../../usecases/object";

class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      qrShow: [],
    };
  }

  // handleInputChange = (e) => {
  //   this.setState({ inputValue: e.target.value });
  // };
  componentDidMount() {
    this.fetchQuery();
  }

  async fetchQuery() {
    const query = {
      where: {
        eventId: this.props.qrObject,
      },
      include: ["event", "participant"],
    };
    const qrShow = await findObjectUseCase().execute("registrations", query);
    console.log("props id", this.props.qrObject);
    this.setState({ qrShow });
    console.log("qrShow", qrShow);
  }

  render() {
    const { onCancel, qrObject } = this.props;
    console.log("qr scan", qrObject);
    const { qrShow } = this.state;
    console.log("qqq", qrShow[0]);
    // const url = `https://event.strikeas1.com/qr-validation-display/${qrShow[0]?.participant?.firstName}/${qrShow[0]?.participant?.middleName}/${qrShow[0]?.participant?.lastName}/${qrShow[0]?.event?.eventName}`;
    const url = `https://cfcc-app.mweeb.com/qr-validation-display/${encodeURIComponent(
      qrShow[0]?.participant?.firstName
    )}/${encodeURIComponent(
      qrShow[0]?.participant?.middleName
    )}/${encodeURIComponent(
      qrShow[0]?.participant?.lastName
    )}/${encodeURIComponent(
      qrShow[0]?.participant?.email
    )}/${encodeURIComponent(qrShow[0]?.event?.eventName)}/${encodeURIComponent(
      qrShow[0]?.event?.eventDate
    )}`;

    return (
      <div className="" style={{ backgroundColor: "#FAFAFA" }}>
        {/* <NavBar /> */}
        <div className="d-flex">
          <div className="m-auto container p-2 p-lg-3">
            <div className="bg-white shadow rounded p-3 p-lg-5">
              <div className="p-3 px-lg-5 py-lg-4">
                <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
                  Registration Successful!
                </h4>
                <p
                  style={{
                    opacity: "0.5",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  Here is your unique QR code that will be scanned for your
                  attendance. Kindly screenshot or save this page. Thank you!
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* Uncomment the below lines to enable dynamic QR code generation based on input
                  <h1>QR Code Generator</h1>
                  <input
                    type="text"
                    value={this.state.inputValue}
                    onChange={this.handleInputChange}
                    placeholder="Enter text to generate QR code"
                    style={{ padding: '10px', marginBottom: '20px', width: '300px', fontSize: '16px' }}
                  /> */}
                  <div style={{ padding: "5px", background: "white" }}>
                    <QRCode value={url} size={200} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QRPage;
