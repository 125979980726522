import BaseFormPresenter from "../../base/BaseFormPresenter";

class EventFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
    } else {
      this.change.acl = this.view.getAcl();
    }
    const notification = {
      name: this.change.eventName,
      description: "hehehe",
      isView: false,
      eventDate: this.change.eventDate,
    };
    console.log("mooo", notification);
    try {
      await this.upsertUseCase.execute(collection, this.change);
      await this.upsertUseCase.execute("notifications", notification);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }
}

export default EventFormPresenter;
