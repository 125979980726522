import React from "react";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../../usecases/object";
import BaseFormPage from "../../../base/BaseFormPage";
import FormFactory from "../../../components/FormFactory";
import { Button, dialog } from "nq-component";
import EventDialogPresenter from "./EventDialogPresenter";

class EventDialog extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      agent: [],
      selectedUser: null,
      selectedTime: { hour: "12", minute: "00", period: "AM" },
      selectedTurnTime: null,
      location: "",
    };
    this.presenter = new EventDialogPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return this.props.schema.collection;
  }

  onSubmitForm(e) {
    e.preventDefault();
    const object = this.props.dispatchObject;
    const user = this.props.user;
    this.presenter.submit(object, user);
  }

  async onChange(value, field) {
    console.log("field", field);

    this.presenter.onChange(value, field); // Call onChange for other fields
  }

  render() {
    const { object } = this.state;

    const schema = this.props.schema;
    console.warn("SDLFKJSDGSD: ", object);
    const schemas = this.props.schemas;

    if (this.state.loading) return <span>Loading...</span>;
    return (
      <div className="p-3 pb-4">
        <h4 className="fw-bold" style={{ color: "#003869" }}>
          Register
        </h4>
        <form onSubmit={this.onSubmitForm.bind(this)}>
          <div className="row g-3">
            <div className="mb-3">
              <FormFactory
                schemas={schemas}
                schema={schema}
                object={object}
                onChange={this.onChange.bind(this)}
                excludeFields={["participant", "event"]}
              />
            </div>
            <div className="col-md-12 d-flex justify-content-center text-center">
              <button
                type="button"
                className="btn btn-light btn-sm fs-sm me-2"
                onClick={() => dialog.close()}
              >
                CANCEL
              </button>
              <Button
                type="submit"
                className="btn btn-sm fs-sm text-nowrap"
                style={{ backgroundColor: "#fdcb5b" }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default EventDialog;
