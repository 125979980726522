import React from "react";
import { Link } from "react-router-dom";
import BasePage from "../../base/BasePage";
import { signUpUseCase } from "../../usecases/user";
import { updateObjectUseCase, upsertUseCase } from "../../usecases/object";
import { Button } from "nq-component";
import withRouter from "../../withRouter";
import FormFactory from "../../components/FormFactory";
import ParticipantsPresenter from "./ParticipantsPresenter";
import schemaSignup from "./schemaSignup";
import NavBar from "../../components/navbar";

class ParticipantsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new ParticipantsPresenter(
      this,
      signUpUseCase(),
      updateObjectUseCase(),
      upsertUseCase()
    );
    this.state = { progress: false };
  }

  formSubmit(e) {
    e.preventDefault();
    this.setState({ progress: true });
    this.presenter.submit(this.state.user);
  }

  onChange(value, field) {
    this.presenter.onChange(value, field);
  }

  render() {
    return (
      <div className="vh-100" style={{ backgroundColor: "#FAFAFA" }}>
        {/* <NavBar /> */}
        <div className="d-flex h-100">
          <div className="m-auto container p-3 p-lg-5">
            <div
              className="text-white fw-bold d-flex"
              style={{
                backgroundColor: "#517EC1",
                borderRadius: "5px 5px 0 0",
              }}
            >
              <i
                class="bi bi-arrow-left"
                style={{
                  padding: "13px",
                  margin: "0px",
                  fontSize: "20px",
                }}
                onClick={() => this.navigateBack()}
              ></i>

              <p
                className="d-flex align-content-center"
                style={{
                  padding: "17px 13px 13px 13px",

                  margin: "0px",
                  fontSize: "14px",
                }}
              >
                New User Information Form
              </p>
            </div>
            <div className="bg-white shadow rounded p-3 p-lg-5">
              <div className="">
                <div className="p-3 px-lg-5 py-lg-4">
                  <h4 className="fw-bold" style={{ color: "#3A57A7" }}>
                    User Information
                  </h4>
                  <p
                    style={{
                      opacity: "0.5",
                      marginBottom: "20px",
                      fontSize: "14px",
                    }}
                  >
                    Please provide the following required fields.
                  </p>
                  <hr></hr>
                  <form onSubmit={this.formSubmit.bind(this)}>
                    <div className="row g-3">
                      <FormFactory
                        schema={schemaSignup}
                        onChange={this.onChange.bind(this)}
                      />
                      <div className="col-md-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="signup_cb_terms"
                          />
                          <label
                            className="form-check-label fs-xs"
                            htmlFor="signup_cb_terms"
                          >
                            By submitting this form, you agree to our
                            <br />
                            <Link to="/terms" target="_blank">
                              Terms and Conditions.
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="text-center">
                        <Button
                          progress={this.state.progress}
                          style={{ backgroundColor: "#1f1c58" }}
                          type="submit"
                          className="btn btn-primary w-50"
                        >
                          {"SUBMIT"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ParticipantsPage);
