import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Progress } from "nq-component";
import ProfilePresenter from "./ProfilePresenter";

const style = {
  backgroundColor: "blue",
};

const circularImageStyle = {
  width: "120px", // Ensure width and height are equal
  height: "120px",
  borderRadius: "50%", // This makes the image circular
  objectFit: "cover", // This ensures the image covers the circle area
};

class ProfilePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ProfilePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
  }

  getCollectionName() {
    return "members";
  }

  render(object) {
    const schema = this.getSchema(this.getCollectionName());

    const { objects, selected, count, progress } = this.state;
    const images = [
      {
        imgUrl: "/youth.jpeg",
        name: "Youth",
        organization: "is_youth",
      },
      {
        imgUrl: "/young-pro.jpg",
        name: "Young Pro",
        organization: "is_young",
      },
      {
        imgUrl: "/entrepreneur.jpeg",
        name: "Entrepreneur",
        organization: "is_entre",
      },
      {
        imgUrl: "/p12.jpg",
        name: "P12",
        organization: "is_p12",
      },
      {
        imgUrl: "/p12.jpg",
        name: "144",
        organization: "is_144",
      },
      {
        imgUrl: "/p12.jpg",
        name: "1728",
        organization: "is_1728",
      },
    ];
    const images1 = [
      {
        imgUrl: "/dancing.jpg",
        name: "Dancing",
        ministry: "is_dance",
      },
      {
        imgUrl: "/multimedia.jpg",
        name: "Multimedia",
        ministry: "is_multimedia",
      },
      {
        imgUrl: "/worship.jpg",
        name: "Worship",
        ministry: "is_worship",
      },
      {
        imgUrl: "/usher.jpg",
        name: "Usher",
        ministry: "is_usher",
      },
      {
        imgUrl: "/it.jpg",
        name: "IT",
        ministry: "is_it",
      },
    ];
    console.log("clp", objects);

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    console.log("uu", user);
    const members = objects[0] || {};
    const filtered = images?.filter(
      (image) => members[image.organization] || false
    );
    const filtered1 = images1?.filter(
      (image) => members[image.ministry] || false
    );
    return (
      <>
        <NavBar />
        <div className="container-fluid">
          <div className="row">
            <main className="col-md-12  px-md-4">
              <div className="d-flex  flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <i
                  class="bi bi-chevron-left me-3"
                  style={{ fontSize: "40px" }}
                  onClick={() => this.navigateBack()}
                ></i>
                <h1 className="h2 mt-2">Profile</h1>
              </div>
              <div
                className="bg-white shadow rounded p-3 px-lg-5 py-lg-4 mb-3"
                style={{ marginTop: "80px" }}
              >
                <div className="Profile" style={{ textAlign: "center" }}>
                  <div className="user-profile" style={{ marginTop: "-80px" }}>
                    <img
                      src={objects[0]?.picture}
                      alt="Profile"
                      style={circularImageStyle}
                    />
                    <h3 className="mt-2 fw-bold">
                      {objects[0]?.firstName} {objects[0]?.lastName}
                    </h3>
                  </div>
                  <div className="main-info m-0">
                    <p className="m-0">
                      <span className="fw-bold"> Birth Date: </span>
                      <span>
                        {" "}
                        {new Date(objects[0]?.birthDate).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </p>
                    <p className="m-0">
                      <span className="fw-bold"> Cell Leader: </span>
                      <span style={{ color: "#004E9B" }}>
                        {objects[0]?.cellLeader.firstName}{" "}
                        {objects[0]?.cellLeader.lastName}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold"> Network Leader: </span>
                      <span style={{ color: "#004E9B" }}>
                        {objects[0]?.netLeaders.firstName}{" "}
                        {objects[0]?.netLeaders.lastName}
                      </span>
                    </p>
                    <div className="m-0 text-center">
                      <p
                        className="fw-bold m-0"
                        style={{
                          color: "#1c4a77",
                          textDecorationLine: "underline",
                        }}
                      >
                        Invited Ranking
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5"></div>
                  <div className="col-sm-2 d-flex justify-content-center mt-2">
                    <p
                      className="fw-bold text-center text-white"
                      style={{
                        backgroundColor: "gold",
                        padding: "15px 10px 10px 10px",
                        borderRadius: "50% 50%",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      1
                    </p>
                  </div>
                  <div className="col-5"></div>
                </div>
              </div>

              <div className="bg-white shadow rounded p-3 px-lg-5 py-lg-4 mb-3">
                <div className="Basic-Information">
                  <h4 className="fw-bold" style={{ opacity: ".75" }}>
                    Basic Information
                  </h4>
                  <p>
                    <span className="fw-bold"> Address: </span>
                    <span>{objects[0]?.address}</span>
                  </p>
                  <p>
                    <span className="fw-bold"> Age: </span>
                    <span>{objects[0]?.age}</span>
                  </p>
                  <p>
                    <span className="fw-bold"> Cell Number: </span>
                    <span>{objects[0]?.cellNo}</span>
                  </p>
                  <p>
                    <span className="fw-bold"> Civil Status: </span>
                    <span>{objects[0]?.civilStatus}</span>
                  </p>
                  <h4 className="fw-bold" style={{ opacity: ".75" }}>
                    Work places
                  </h4>
                  <p>Address: Pasig City</p>
                  <h4 className="fw-bold" style={{ opacity: ".75" }}>
                    Organization
                  </h4>
                  <div className="my-4 d-flex d-md-none flex-nowrap overflow-auto">
                    {filtered.map((imageSrc, index) => (
                      <div className="text-center col-6 col-md-3" key={index}>
                        <img
                          src={imageSrc.imgUrl}
                          alt={`Circular content ${index}`}
                          style={circularImageStyle}
                        />
                        <p className="p-2 text-dark">{imageSrc.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="row row-cols-1 row-cols-sm-4 d-none d-sm-flex">
                    {filtered.map((imageSrc, index) => (
                      <div className="col-auto text-center" key={index}>
                        <img
                          src={imageSrc.imgUrl}
                          alt={`Circular content ${index}`}
                          style={circularImageStyle}
                        />
                        <p className="p-2 text-dark">{imageSrc.name}</p>
                      </div>
                    ))}
                  </div>
                  <h4 className="fw-bold" style={{ opacity: ".75" }}>
                    Church Ministry
                  </h4>
                  <div className="my-4 d-flex d-md-none flex-nowrap overflow-auto">
                    {filtered1.map((imageSrc, index) => (
                      <div className="text-center col-6 col-md-3" key={index}>
                        <img
                          src={imageSrc.imgUrl}
                          alt={`Circular content ${index}`}
                          style={circularImageStyle}
                        />
                        <p className="p-2 text-dark">{imageSrc.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="row row-cols-1 row-cols-sm-4 d-none d-sm-flex">
                    {filtered1.map((imageSrc, index) => (
                      <div className="col-auto text-center" key={index}>
                        <img
                          src={imageSrc.imgUrl}
                          alt={`Circular content ${index}`}
                          style={circularImageStyle}
                        />
                        <p className="p-2 text-dark">{imageSrc.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProfilePage);
