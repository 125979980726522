import React from "react";
import click from "../../click";
import classNames from "../../classNames";
import printComponent from "../../printComponent";

const defaultProps = {
  height: "250px",
};

function InputImage({ className, value, onChange, saveImage, print, height }) {
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const printRef = React.useRef();

  function readImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  function onFileSelected(file) {
    readImage(file);
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    saveImage
      .execute(file, options)
      .then((result) => {
        setImage(result.url);
        setProgress(0);
        onChange(result.url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onClick() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "img";
    input.onchange = (e) => {
      const [file] = e.target.files;
      file && onFileSelected(file);
    };
    click(input);
  }

  function onClickIcon(e) {
    e.stopPropagation();
    printComponent(printRef.current, "image")
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <div className="d-none">
        <div ref={printRef}>
          {value && <img src={value} alt="Image" style={{ margin: "100px" }} />}
        </div>
      </div>
      <div
        onClick={onClick}
        className={classNames(
          "rounded bg-light d-flex position-relative cursor-pointer",
          className
        )}
        style={{ height }}
      >
        <div className="position-absolute h-100 w-100 d-flex justify-content-center">
          <img className="img-fluid" src={image || value} />
        </div>
        {!(image || value) && (
          <div className="position-absolute h-100 w-100 d-flex">
            <i className="bi bi-camera-fill display-3 text-dark m-auto" />
          </div>
        )}
        {progress > 0 && (
          <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
        )}
        {progress > 0 && (
          <div className="position-absolute h-100 w-100 d-flex">
            <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
          </div>
        )}
        {print && (
          <div className="position-absolute w-100 text-end p-2">
            <button
              type="button"
              onClick={onClickIcon}
              className="btn btn-secondary"
            >
              <i className="bi bi-printer-fill"></i>
            </button>
          </div>
        )}
      </div>
    </>
  );
}

InputImage.defaultProps = defaultProps;
export default InputImage;
