// import React from "react";
// import { Table } from "nq-component";
// import { findObjectUseCase } from "../../usecases/object";
// import { exportCSVUseCase } from "../../usecases/csv/usecases";

// const defaultProps = {
//   where: {},
// };

// function DialogTable({ schema, where, onCancel, title }) {
//   const [objects, setObjects] = React.useState([]);
//   const [progress, setProgress] = React.useState(false);
//   React.useEffect(() => {
//     const query = { where };

//     async function fetch() {
//       try {
//         setProgress(true);
//         const find = findObjectUseCase();
//         const objects = await find.execute(schema.collection, query);
//         setObjects(objects);
//         setProgress(false);
//       } catch (error) {
//         setProgress(false);
//         console.error(error);
//       }
//     }

//     fetch();
//   }, [schema, where]);

//   async function onClickExport() {
//     const exportCSV = exportCSVUseCase();
//     exportCSV.execute(objects, schema.collection).then(() => {
//       onCancel();
//     });
//   }

//   return (
//     <div className="p-3 pb-4">
//       <h4 className="fw-bold">Members</h4>
//       <div className="overflow-auto mt-3" style={{ maxHeight: "400px" }}>
//         <Table
//           fields={schema.fields}
//           progress={progress}
//           objects={objects}
//           excludeFields={Object.keys(schema.fields).reduce(
//             (acc, key) => {
//               const options = schema.fields[key];
//               if (options.read === false) {
//                 acc.push(key);
//               }
//               switch (options._type || options.type) {
//                 case "Relation":
//                 case "Array":
//                 case "Object":
//                 case "File":
//                   acc.push(key);
//                   break;
//                 default:
//               }
//               return acc;
//             },
//             ["id", "acl", "password"]
//           )}
//           className="mt-3"
//         />
//       </div>
//       <div className="text-end mt-3">
//         <button
//           onClick={onClickExportPdf}
//           type="button"
//           className="btn btn-sm fs-sm text-white me-2"
//           style={{ backgroundColor: "#416BC1" }}
//         >
//           <i className="bi bi-file-spreadsheet me-2 text-white"></i>EXPORT PDF
//         </button>
//         <button
//           onClick={onClickExport}
//           type="button"
//           className="btn btn-sm fs-sm text-white"
//           style={{ backgroundColor: "#416BC1" }}
//         >
//           <i className="bi bi-file-spreadsheet me-2 text-white"></i>EXPORT EXCEL
//         </button>
//         <button
//           type="button"
//           className="btn btn-light btn-sm fs-sm ms-2"
//           onClick={onCancel}
//         >
//           CANCEL
//         </button>
//       </div>
//     </div>
//   );
// }

// DialogTable.defaultProps = defaultProps;
// export default DialogTable;

import React from "react";
import { Table } from "nq-component";
import { findObjectUseCase } from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import jsPDF from "jspdf";
import "jspdf-autotable";

const defaultProps = {
  where: {},
};

function DialogTable({ schema, where, onCancel, title }) {
  const [objects, setObjects] = React.useState([]);
  const [progress, setProgress] = React.useState(false);

  React.useEffect(() => {
    const query = { where };

    async function fetch() {
      try {
        setProgress(true);
        const find = findObjectUseCase();
        const objects = await find.execute(schema.collection, query);
        setObjects(objects);
        setProgress(false);
      } catch (error) {
        setProgress(false);
        console.error(error);
      }
    }

    fetch();
  }, [schema, where]);

  async function onClickExport() {
    const exportCSV = exportCSVUseCase();
    exportCSV.execute(objects, schema.collection).then(() => {
      onCancel();
    });
  }

  async function onClickExportPdf() {
    const doc = new jsPDF();

    // Define table headers dynamically
    const headers = Object.keys(schema.fields)
      .filter(
        (key) =>
          schema.fields[key].read !== false &&
          !["Relation", "Array", "Object", "File"].includes(
            schema.fields[key]._type || schema.fields[key].type
          ) &&
          !["id", "acl", "password", "picture", "object"].includes(key) // Exclude specific fields
      )
      .map((key) => schema.fields[key].label || key);

    // Define table rows based on objects
    const data = objects.map((obj) =>
      headers.map((header) => {
        const value = obj[header];
        if (typeof value === "object" && value !== null) {
          return JSON.stringify(value); // Flatten objects
        }
        return value || ""; // Handle null/undefined values
      })
    );

    // Add title to the PDF
    doc.setFontSize(14);
    doc.text(title || "Members", 14, 10);

    // Create the table using autoTable with Bootstrap-like styling
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 20,
      theme: "grid", // Set to 'grid' for a classic table style
      styles: {
        fontSize: 10,
        cellPadding: 4, // Add padding similar to Bootstrap tables
        overflow: "linebreak", // Wrap text within cells
      },
      headStyles: {
        fillColor: [240, 240, 240], // Light gray header (Bootstrap-like)
        textColor: [0, 0, 0], // Black text in header
        fontSize: 12,
        fontStyle: "bold",
        halign: "center", // Center align header text
      },
      bodyStyles: {
        textColor: [0, 0, 0], // Black text for body
        halign: "left", // Left align cell content
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Light gray alternating rows (Bootstrap-like)
      },
      columnStyles: {
        0: { cellWidth: 30 }, // Adjust the width of the first column
      },
    });

    // Open the PDF in a new tab for printing
    window.open(doc.output("bloburl"), "_blank");
  }

  return (
    <div className="p-3 pb-4">
      <h4 className="fw-bold">Members</h4>
      <div className="overflow-auto mt-3" style={{ maxHeight: "400px" }}>
        <Table
          fields={schema.fields}
          progress={progress}
          objects={objects}
          excludeFields={Object.keys(schema.fields).reduce(
            (acc, key) => {
              const options = schema.fields[key];
              if (options.read === false) {
                acc.push(key);
              }
              switch (options._type || options.type) {
                case "Relation":
                case "Array":
                case "Object":
                case "File":
                  acc.push(key);
                  break;
                default:
              }
              return acc;
            },
            ["id", "acl", "password"]
          )}
          className="mt-3"
        />
      </div>
      <div className="text-end mt-3">
        <button
          onClick={onClickExportPdf}
          type="button"
          className="btn btn-sm fs-sm text-white me-2"
          style={{ backgroundColor: "#416BC1" }}
        >
          <i className="bi bi-file-pdf me-2 text-white"></i>EXPORT PDF
        </button>
        <button
          onClick={onClickExport}
          type="button"
          className="btn btn-sm fs-sm text-white"
          style={{ backgroundColor: "#416BC1" }}
        >
          <i className="bi bi-file-spreadsheet me-2 text-white"></i>EXPORT EXCEL
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm fs-sm ms-2"
          onClick={onCancel}
        >
          CANCEL
        </button>
      </div>
    </div>
  );
}

DialogTable.defaultProps = defaultProps;
export default DialogTable;
