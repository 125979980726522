import { upsertUseCase } from "../../usecases/object";

class ParticipantsPresenter {
  constructor(view, signUpUseCase, updateObjectUseCase, upsertUseCase) {
    this.view = view;
    this.signUpUseCase = signUpUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.upsertUseCase = upsertUseCase;
    this.change = {};
  }

  onChange(value, field) {
    this.change[field] = value;
  }

  submit() {
    console.log("change", this.change);
    console.log("change", this.change);
    const { email, password, confirmPassword, ...others } = this.change;

    // const eventName = sessionStorage.getItem("selectedEventName");

    if (password !== confirmPassword) {
      this.view.showError("password must be the same");
      return;
    }
    const user = {
      ...others,
      status: "Pending",
      user: {
        username: email,
        email,
        password,
      },
    };

    console.log("memebers semd", user);
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.upsertUseCase.execute("members", user))
      .then((response) => {
        console.log("response", response);
        this.view.hideProgress();
        this.view.showSuccess(
          "Congratulations, your account has been successfully created."
        );
        // this.view.navigateTo(`/qr-validation/${response.id}`);
        this.view.navigateTo(`/signin`);
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default ParticipantsPresenter;
