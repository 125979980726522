import React from "react";
import { dialog, Button } from "nq-component";

import {
  addSchemaUseCase,
  updateSchemaUseCase,
  deleteSchemaUseCase,
} from "../../usecases/schema/usecases";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import { Progress, InfiniteScroll } from "nq-component";
import withRouter from "../../withRouter";
import Search from "../../components/Search";
import BaseListPage from "../../base/BaseListPage";
import NavBar from "../../components/navbar";
import InputFactory from "../../components/InputFactory";
import MemberPresenter from "./ MemberPresenter";
import Table from "../../components/Table";
import Table1 from "../../components/Table1";

class MemberPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new MemberPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase(),
      addSchemaUseCase(),
      updateSchemaUseCase(),
      deleteSchemaUseCase()
    );
  }
  componentDidUpdate(prevProps, prevState) {
    this.presenter.componentDidUpdate(prevProps, prevState);
  }

  getCollectionName() {
    return "members";
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value).length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.searchSubmit(where);
  }

  onClickItemEdit(index) {
    this.presenter.onClickItem(index);
  }

  onClickDelete(index) {
    this.presenter.onClickDelete(index);
  }

  async clickInvite(index, object) {
    // dialog.close()
    const circularImageStyle = {
      width: "100px", // Ensure width and height are equal
      height: "100px",
      borderRadius: "50%", // This makes the image circular
      objectFit: "cover", // This ensures the image covers the circle area
    };
    const objects = this.state;
    const invite = this.state.objects[index];
    const query = {
      where: {
        firstName: invite?.invitedBy?.firstName,
      },
      include: ["all"],
    };
    const queryInvite = await findObjectUseCase().execute("members", query);
    console.log("invite", queryInvite);
    const birthdate = new Date(invite?.invitedBy?.birthDate);
    dialog.fire({
      html: (
        <>
          <div className="text-end">
            <i
              className="bi bi-x-lg text-end fs-5"
              onClick={() => dialog.close()}
            ></i>
            <div className="invite-profile text-center mt-3 align-items-center mb-5">
              <img
                src={invite?.invitedBy?.picture}
                alt="Profile"
                style={circularImageStyle}
              />
              <p className="invite-name mt-2 fw-bold text-dark">
                {invite?.invitedBy?.firstName} {invite?.invitedBy?.lastName}
              </p>

              <p
                className="invite-role fw-bold"
                style={{
                  opacity: "0.5",
                  fontSize: "12px",
                  marginTop: "-17px",
                  color: "#004E9B",
                }}
              >
                {queryInvite[0]?.is_p12 === true ? "P12," : null}{" "}
                {queryInvite[0]?.is_young === true ? "Young Pro," : null}{" "}
                {queryInvite[0]?.is_144 === true ? "144," : null}{" "}
                {queryInvite[0]?.is_1728 === true ? "1728," : null}{" "}
                {queryInvite[0]?.is_entre === true ? "Entrepreneur," : null}{" "}
                {queryInvite[0]?.is_youth === true ? "Youth," : null}
              </p>
              <div className="text-center invite-info mt-3 d-flex justify-content-center align-items-center">
                <div
                  className="align-items-center"
                  style={{ textAlign: "justify" }}
                >
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-geo-alt-fill me-2"
                      style={{ color: "#004E9B" }}
                    ></i>{" "}
                    Address:{" "}
                    <span className="fw-normal">
                      {invite?.invitedBy?.address}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-suit-heart-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Civil Status:{" "}
                    <span className="fw-normal">
                      {invite?.invitedBy?.civilStatus}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-gender-ambiguous"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Gender:{" "}
                    <span className="fw-normal">
                      {invite?.invitedBy?.gender}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-person-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Age:{" "}
                    <span className="fw-normal">{invite?.invitedBy?.age}</span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-telephone-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell No.:{" "}
                    <span className="fw-normal">
                      {invite?.invitedBy?.cellNo}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-calendar-event"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Birthdate:{" "}
                    <span className="fw-normal">
                      {birthdate?.toISOString().split("T")[0]}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-people-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Invited By:{" "}
                    <span
                      className="fw-normal"
                      style={{ cursor: "pointer", color: "skyblue" }}
                    >
                      {queryInvite[0]?.invitedBy?.firstName}{" "}
                      {queryInvite[0]?.invitedBy?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-diagram-3-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Net Leader:{" "}
                    <span
                      className="fw-normal "
                      style={{ cursor: "pointer", color: "skyblue" }}
                    >
                      {queryInvite[0]?.netLeaders?.firstName}{" "}
                      {queryInvite[0]?.netLeaders?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-star-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell Leader:{" "}
                    <span
                      className="fw-normal"
                      style={{ cursor: "pointer", color: "skyblue" }}
                    >
                      {queryInvite[0]?.cellLeader?.firstName}{" "}
                      {queryInvite[0]?.cellLeader?.lastName}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  async clickNetLeader(index, object) {
    const circularImageStyle = {
      width: "100px", // Ensure width and height are equal
      height: "100px",
      borderRadius: "50%", // This makes the image circular
      objectFit: "cover", // This ensures the image covers the circle area
    };
    const invite = this.state.objects[index];
    const query = {
      where: {
        firstName: invite?.netLeaders?.firstName,
      },
      include: ["all"],
    };
    const queryInvite = await findObjectUseCase().execute("members", query);
    console.log("invite", invite);
    const birthdate = new Date(invite?.netLeaders?.birthDate);
    dialog.fire({
      html: (
        <>
          <div className="text-end">
            <i
              className="bi bi-x-lg text-end fs-5"
              onClick={() => dialog.close()}
            ></i>
            <div className="invite-profile text-center mt-3 align-items-center mb-5">
              <img
                src={invite?.netLeaders?.picture}
                alt="Profile"
                style={circularImageStyle}
              />
              <p className="invite-name mt-2 fw-bold text-dark">
                {invite?.netLeaders?.firstName} {invite?.netLeaders?.lastName}
              </p>

              <p
                className="invite-role fw-bold"
                style={{
                  opacity: "0.5",
                  fontSize: "12px",
                  marginTop: "-17px",
                  color: "#004E9B",
                }}
              >
                {queryInvite[0]?.is_p12 === true ? "P12," : null}{" "}
                {queryInvite[0]?.is_young === true ? "Young Pro," : null}{" "}
                {queryInvite[0]?.is_144 === true ? "144," : null}{" "}
                {queryInvite[0]?.is_1728 === true ? "1728," : null}{" "}
                {queryInvite[0]?.is_entre === true ? "Entrepreneur," : null}{" "}
                {queryInvite[0]?.is_youth === true ? "Youth," : null}
              </p>
              <div className="text-center invite-info mt-3 d-flex justify-content-center align-items-center">
                <div
                  className="align-items-center"
                  style={{ textAlign: "justify" }}
                >
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-geo-alt-fill me-2"
                      style={{ color: "#004E9B" }}
                    ></i>{" "}
                    Address:{" "}
                    <span className="fw-normal">
                      {invite?.netLeaders?.address}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-suit-heart-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Civil Status:{" "}
                    <span className="fw-normal">
                      {invite?.netLeaders?.civilStatus}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-gender-ambiguous"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Gender:{" "}
                    <span className="fw-normal">
                      {invite?.netLeaders?.gender}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-person-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Age:{" "}
                    <span className="fw-normal">{invite?.netLeaders?.age}</span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-telephone-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell No.:{" "}
                    <span className="fw-normal">
                      {invite?.netLeaders?.cellNo}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-calendar-event"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Birthdate:{" "}
                    <span className="fw-normal">
                      {birthdate.toISOString().split("T")[0]}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-people-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Invited By:{" "}
                    <span
                      className="fw-normal"
                      onClick={() => this.clickInvite(index)}
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.invitedBy?.firstName}{" "}
                      {queryInvite[0]?.invitedBy?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-diagram-3-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Net Leader:{" "}
                    <span
                      className="fw-normal "
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.netLeaders?.firstName}{" "}
                      {queryInvite[0]?.netLeaders?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-star-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell Leader:{" "}
                    <span
                      className="fw-normal"
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.cellLeader?.firstName}{" "}
                      {queryInvite[0]?.cellLeader?.lastName}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  async clickCellLeader(index, object) {
    const circularImageStyle = {
      width: "100px", // Ensure width and height are equal
      height: "100px",
      borderRadius: "50%", // This makes the image circular
      objectFit: "cover", // This ensures the image covers the circle area
    };
    const invite = this.state.objects[index];
    const query = {
      where: {
        firstName: invite?.cellLeader?.firstName,
      },
      include: ["all"],
    };
    const queryInvite = await findObjectUseCase().execute("members", query);
    console.log("invite", invite);
    const birthdate = new Date(invite?.cellLeader?.birthDate);
    dialog.fire({
      html: (
        <>
          <div className="text-end">
            <i
              className="bi bi-x-lg text-end fs-5"
              onClick={() => dialog.close()}
            ></i>
            <div className="invite-profile text-center mt-3 align-items-center mb-5">
              <img
                src={invite?.cellLeader?.picture}
                alt="Profile"
                style={circularImageStyle}
              />
              <p className="invite-name mt-2 fw-bold text-dark">
                {invite?.cellLeader?.firstName} {invite?.cellLeader?.lastName}
              </p>

              <p
                className="invite-role fw-bold"
                style={{
                  opacity: "0.5",
                  fontSize: "12px",
                  marginTop: "-17px",
                  color: "#004E9B",
                }}
              >
                {queryInvite[0]?.is_p12 === true ? "P12," : null}{" "}
                {queryInvite[0]?.is_young === true ? "Young Pro," : null}{" "}
                {queryInvite[0]?.is_144 === true ? "144," : null}{" "}
                {queryInvite[0]?.is_1728 === true ? "1728," : null}{" "}
                {queryInvite[0]?.is_entre === true ? "Entrepreneur," : null}{" "}
                {queryInvite[0]?.is_youth === true ? "Youth," : null}
              </p>
              <div className="text-center invite-info mt-3 d-flex justify-content-center align-items-center">
                <div
                  className="align-items-center"
                  style={{ textAlign: "justify" }}
                >
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-geo-alt-fill me-2"
                      style={{ color: "#004E9B" }}
                    ></i>{" "}
                    Address:{" "}
                    <span className="fw-normal">
                      {invite?.cellLeader?.address}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-suit-heart-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Civil Status:{" "}
                    <span className="fw-normal">
                      {invite?.cellLeader?.civilStatus}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-gender-ambiguous"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Gender:{" "}
                    <span className="fw-normal">
                      {invite?.cellLeader?.gender}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-person-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Age:{" "}
                    <span className="fw-normal">{invite?.cellLeader?.age}</span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-telephone-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell No.:{" "}
                    <span className="fw-normal">
                      {invite?.cellLeader?.cellNo}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-calendar-event"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Birthdate:{" "}
                    <span className="fw-normal">
                      {birthdate.toISOString().split("T")[0]}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-people-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Invited By:{" "}
                    <span
                      className="fw-normal"
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.invitedBy?.firstName}{" "}
                      {queryInvite[0]?.invitedBy?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-diagram-3-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Net Leader:{" "}
                    <span
                      className="fw-normal "
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.netLeaders?.firstName}{" "}
                      {queryInvite[0]?.netLeaders?.lastName}
                    </span>
                  </p>
                  <p style={{ marginBottom: "1px", fontWeight: "bold" }}>
                    <i
                      className="bi bi-star-fill"
                      style={{ marginRight: "5px", color: "#004E9B" }}
                    ></i>{" "}
                    Cell Leader:{" "}
                    <span
                      className="fw-normal"
                      style={{
                        cursor: "pointer",
                        color: "skyblue",
                        textDecoration: "underline",
                      }}
                    >
                      {queryInvite[0]?.cellLeader?.firstName}{" "}
                      {queryInvite[0]?.cellLeader?.lastName}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      footer: false,
    });
  }

  onClickItemApproved(index) {
    dialog.fire({
      html: (
        <div className="m-3">
          <div className="text-center mb-3">
            <h5>Are you sure you want to approve this member?</h5>
          </div>
          <div className="text-center">
            <button
              className="btn text-white me-2"
              style={{ backgroundColor: "#FFB200" }}
              onClick={() => dialog.close()}
            >
              Cancel
            </button>
            <button
              className="btn text-white"
              style={{ backgroundColor: "#517ec1" }}
              onClick={() => this.presenter.onClickActive(index)}
            >
              Confirm
            </button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    return (
      <div className="m-3">
        <div className="d-flex">
          <h6 className="fw-bold me-1">First Name:</h6>
          <h6>{object.firstName}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Middle Name:</h6>
          <h6>{object.middleName}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Last Name:</h6>
          <h6>{object.lastName}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Address:</h6>
          <h6>{object.address}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Civil Status:</h6>
          <h6>{object.civilStatus}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Gender:</h6>
          <h6>{object.gender}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Age:</h6>
          <h6>{object.age}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Cell Number:</h6>
          <h6>{object.cellNo}</h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Birthdate:</h6>
          <h6>
            {new Date(object.birthDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Invited By:</h6>
          <h6
            onClick={() => this.clickInvite(index)}
            style={{
              cursor: "pointer",
              color: "skyblue",
              textDecoration: "underline",
            }}
          >
            {object?.invitedBy?.firstName} {object?.invitedBy?.lastName}
          </h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Net Leader:</h6>
          <h6
            onClick={() => this.clickNetLeader(index)}
            style={{
              cursor: "pointer",
              color: "skyblue",
              textDecoration: "underline",
            }}
          >
            {object?.netLeaders?.firstName} {object?.netLeaders?.lastName}
          </h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Cell Leader:</h6>
          <h6
            onClick={() => this.clickCellLeader(index)}
            style={{
              cursor: "pointer",
              color: "skyblue",
              textDecoration: "underline",
            }}
          >
            {object?.cellLeader?.firstName} {object?.cellLeader?.lastName}
          </h6>
        </div>
        <div className="d-flex">
          <h6 className="fw-bold me-1">Organization:</h6>
          <h6
            onClick={() => this.clickInvite(index)}
            style={{ cursor: "pointer", color: "skyblue" }}
          >
            {object.organization}
          </h6>
        </div>

        <div className="mt-3">
          <Button
            className="btn shadow-sm text-white"
            onClick={() => this.onClickItemEdit(index)}
            style={{
              width: "70px",
              height: "50px",
              borderRadius: "5px",
              backgroundColor: "#416BC1",
            }}
          >
            {" "}
            Edit
          </Button>
          <Button
            className="btn shadow-sm text-white ms-2"
            onClick={() => this.onClickItemApproved(index)}
            style={{
              width: "75px",
              height: "50px",
              borderRadius: "5px",
              backgroundColor: "#416BC1",
            }}
          >
            Approve
          </Button>
          <Button
            className="btn shadow-sm text-white ms-2"
            onClick={() => this.onClickDelete(index)}
            style={{
              width: "70px",
              height: "50px",
              borderRadius: "5px",
              backgroundColor: "#CF3B3B",
            }}
          >
            {" "}
            Delete
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const title = this.getArgument().name;
    const ci = this.getQuery();
    const params = this.getParams();
    console.log("params", params);
    console.log("ci", ci);
    console.log("title", title);

    const { objects, selected, count, progress, loading } = this.state;
    console.log("clp", objects);
    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || title}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <Table1
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                onCollapse={this.onCollapse.bind(this)}
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "middleName",
                    "address",
                    "civilStatus",
                    "gender",
                    "age",
                    "cellNo",
                    "birthDate",
                    "invitedBy",
                    "netLeaders",
                    "cellLeader",
                    "email",
                    "picture",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
                loading={loading}
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn shadow-sm"
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#416BC1",
            }}
          >
            <i className="bi bi-plus-lg text-white" />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(MemberPage);
