import BaseFormPresenter from "../../base/BaseFormPresenter";

class UserFormPresenter extends BaseFormPresenter {
  onChangeObject(object) {
    this.change = object;
    this.object = object;
  }

  async save() {
    const collection = this.view.getCollectionName();
    const user = this.view.getCurrentUser();
    const userInfo = {
      email: this.change.email,
      username: this.change.email,
      password: this.change.password,
      roles: this.change.roles,
    };
    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.user = userInfo;
    } else {
      this.change.acl = this.view.getAcl();
      this.change.createdBy = user.id;
      this.change.user = userInfo;
    }

    console.log("this.change", this.change);
    try {
      // await this.upsertUseCase.execute("users", this.change.user);
      // delete this.change.user.username;
      // delete this.change.user.email;
      await this.upsertUseCase.execute(collection, this.change);
      this.change = {};
    } catch (error) {
      throw error; // rethrow the error to be caught by the caller
    }
  }

  async submit() {
    if (Object.values(this.change).length === 0) {
      this.view.showSuccessSnackbar("Successfully saved!");
      return;
    }
    try {
      this.view.submitting();
      await this.save();
      this.view.submissionSuccess();
      this.view.showSuccessSnackbar("Successfully saved!");
    } catch (error) {
      this.view.submissionError(error);
      this.view.showError(error);
    }
  }
}

export default UserFormPresenter;
