import React from "react";

function DialogDelete({ onClickClose, onClickItemDelete }) {
  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <div className="text-center align-items-center justify-content-center mt-3">
          <h2 className="text-danger" style={{ marginLeft: "35px" }}>
            <div
              className="text-center"
              style={{
                backgroundColor: "#FFB200",
                color: "white",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bi bi-trash-fill"></i>
            </div>
          </h2>
          <h4 className="text-dark">Delete Data</h4>
        </div>
        <div className="mt-2 text-center">
          <p className="mb-0">Are you sure you want to delete this event?</p>
        </div>
        <div className="d-flex justify-content-center text-center mb-3 mt-2">
          <button
            className="btn me-2"
            onClick={() => onClickClose()}
            style={{ backgroundColor: "#FFB200", color: "white" }}
          >
            Cancel
          </button>
          <button
            className="btn text-white"
            style={{ backgroundColor: "#517ec1", color: "white" }}
            onClick={() => onClickItemDelete()}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}

export default DialogDelete;
