import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import UserPresenter from "./UserPresenter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, dialog, InfiniteScroll, Progress } from "nq-component";
import InputFactory from "../../components/InputFactory";
import Search from "../../components/Search";
import Table from "../../components/Table";
import DialogDelete from "./components/DialogDelete";

const style = {
  backgroundColor: "blue",
};
class UserPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new UserPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
  }

  getCollectionName() {
    return "members";
  }

  onClickProfile(index) {
    this.presenter.onClickProfile(index);
  }

  onClickDelete(index) {
    this.presenter.onClickDelete(index);
  }

  onClickItemDelete(index, object) {
    dialog.fire({
      html: (
        <DialogDelete
          onClickClose={() => dialog.close()}
          onClickItemDelete={() => this.onClickDelete(index)}
        />
      ),
      footer: false,
    });
  }

  onCollapse(index, object) {
    return (
      <>
        <div className="d-flex">
          {object.picture && (
            <div
              className="mb-3"
              style={{
                width: "100px",
                height: "100px",
              }}
            >
              <img
                className="w-100 h-100"
                style={{ objectFit: "cover" }}
                src={object.picture}
              />
            </div>
          )}
          <ul className="list-unstyled ms-1">
            <li>
              <span className="ms-2 fw-bold text-dark me-2">Name:</span>
              <span
                className="fs-sm text-nowrap"
                onClick={() => this.onClickProfile(index)}
                style={{ color: "#87CEEB", cursor: "pointer" }}
              >
                {object?.firstName} {object?.middleName} {object?.lastName}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold text-dark me-2">Contact #:</span>
              <span className="fs-sm text-nowrap">{object.cellNo}</span>
            </li>

            <li>
              <span className="ms-2 fw-bold text-dark me-2">BirthDate:</span>
              <span className="fs-sm text-nowrap">
                {new Date(object.birthDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            </li>
            <li>
              <span className="ms-2 fw-bold text-dark me-2">Age:</span>
              <span className="fs-sm text-nowrap">{object.age} years old</span>
            </li>
            {/* <li>
              <span className="ms-2 fw-bold text-dark me-2">Role:</span>
              <span className="fs-sm text-nowrap">{object.roles[0]?.id}</span>
            </li> */}
            <li>
              <span className="ms-2 fw-bold text-dark me-2">Address:</span>
              <span className="fs-sm text-nowrap">{object.address}</span>
            </li>
          </ul>
        </div>

        <div className="d-flex">
          <div className="me-2">
            <button
              className="btn"
              onClick={() => this.onClickItem(index)}
              style={{ backgroundColor: "#416BC1", color: "white" }}
            >
              <i className="bi bi-pencil-square me-1"></i>EDIT
            </button>
          </div>
          {/* <div className="me-2">
            <button
              className="btn text-white"
              onClick={() => this.onClickItem(index)}
              style={{ backgroundColor: "#416BC1" }}
            >
              <i className="bi bi-pencil-square me-1"></i>ACTIVE
            </button>
          </div> */}
          <div className="me-2">
            <button
              className="btn btn-danger"
              onClick={() => this.onClickItemDelete(index, object)}
            >
              {" "}
              <i className="bi bi-trash me-1"></i>DELETE
            </button>
          </div>
          <div className="">
            {object.statuses === "Approved" && (
              <button
                className="btn text-white"
                onClick={() => this.onClickItemInvoice(index, object)}
                style={{ backgroundColor: "#ebbf38" }}
              >
                {" "}
                <i className="bi bi-plus me-1"></i>CREATE INVOICE
              </button>
            )}
          </div>
        </div>
      </>
    );
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  onClickItem(index, field) {
    this.presenter.onClickItem(index, field);
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());

    const { objects, selected, count, progress } = this.state;
    console.log("clp", objects);

    if (!schema) return <Progress />;
    const user = this.getCurrentUser();
    console.log("uu", user);
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {user.roles[0].name === "pastor"
                    ? "Primary 12"
                    : "My Network"}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <Table
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  [
                    "acl",
                    "password",
                    "username",
                    "id",
                    "picture",
                    "signature",
                    "email",
                    "createdBy",
                    "middleName",
                    "address",
                    "civilStatus",
                    "age",
                    "cellNo",
                    "birthDate",
                    "invitedBy",
                    "netLeaders",
                    "cellLeader",
                    "roles",
                    // "memberDetails",
                  ]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                onCollapse={this.onCollapse.bind(this)}
                className="mt-3"
                style={style}
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn shadow-sm"
            onClick={this.onClickAdd.bind(this)}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#416BC1",
            }}
          >
            <i
              className="bi bi-plus-lg"
              style={{ fontSize: "20px", color: "white" }}
            />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(UserPage);
