import React from "react";
import { NavBar as Nav, Layout, LogoHolder } from "nq-component";
import classNames from "../../classNames";
import { useNavigate } from "react-router-dom";

function NavBar2({ action, unseenCount }) {
  const classes = classNames("navbar navbar-expand-lg ");
  const { collapsed, setCollapse } = React.useContext(Layout.Context);
  const location = useNavigate();

  function onClickNavigate() {
    setCollapse(!collapsed);
  }

  React.useEffect(() => {
    setCollapse(false);
  }, [setCollapse]);

  const move = () => {
    location("/notification");
  };

  const backHome = () => {
    location("/");
  };

  const styles = {
    imgContainer: {
      backgroundColor: "white",
      padding: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "250px",
      height: "50px",
      borderRadius: "5px",
    },
    img: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  };

  return (
    <nav className={classes} style={{ backgroundColor: "#416bc1" }}>
      <div className="container-fluid">
        <button
          onClick={onClickNavigate}
          type="button"
          className="btn btn-sm btn-link fs-4 ps-0 text-dark"
        >
          <i className="bi bi-justify-left text-white"></i>
        </button>
        {/* <a href="# " className="navbar-brand me-auto"></a> */}

        <div onClick={backHome} className="d-flex">
          <div style={styles.imgContainer}>
            <img src={"/conquerorslogo.svg"} alt="logo" style={styles.img} />
          </div>
        </div>
        {/* <button
        onClick={onClickNavigate}
        type="button"
        className="btn btn-sm btn-link fs-4 ps-0 text-dark me-auto"
      >
        <i className="bi bi-justify-left text-white"></i>
      </button> */}
        {/* <div className="d-flex align-items-center">
          <button onClick={move} className="btn btn-sm btn-link fs-5">
            <i className="bi bi-bell-fill text-white"></i>
            {unseenCount > 0 && (
              <span
                style={{
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  fontSize: "12px",
                  padding: "2px 6px",
                  marginLeft: "4px",
                }}
              >
                {unseenCount}
              </span>
            )}
          </button>
        </div> */}
        {(action && action()) || (
          <button
            // onClick={handleCartClick}
            onClick={move}
            type="button"
            className="btn btn-sm btn-link fs-4 text-dark position-relative"
          >
            <i
              className="bi bi-bell-fill text-white"
              style={{ fontSize: "25px", color: "#517ec1" }}
            ></i>
            {unseenCount > 0 && (
              <span
                className="badge rounded-circle bg-danger position-absolute"
                style={{
                  top: "7px",
                  right: "0px",
                  fontSize: "0.7rem",
                  width: "18px",
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                {unseenCount}
              </span>
            )}
          </button>
        )}

        {/* <a href="# " className="navbar-brand me-auto"></a>
        {(!action && (
          <button onClick={move} className="btn btn-sm btn-link fs-5">
            <i class="bi bi-bell-fill text-white"></i>
          </button>
        )) || <div />} */}
      </div>
    </nav>
  );
}

export default NavBar2;
