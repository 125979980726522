// import React from "react";
// import withRouter from "../../withRouter";
// import BaseListPage from "../../base/BaseListPage";
// import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
// import NavBar from "../../components/navbar";
// import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
// import EventPresenter from "./EventPresenter";
// import EventDialog from "./components/EventDialog";

// class EventPage extends BaseListPage {
//   constructor(props) {
//     super(props);
//     this.state = {
//       objects: [],
//       selected: [],
//       loading: true,
//       total: 0,
//       count: 0,
//       report: [],
//       registrations: [],
//     };
//     this.presenter = new EventPresenter(
//       this,
//       findObjectUseCase(),
//       countObjectUseCase()
//     );
//   }

//   getCollectionName() {
//     return "events";
//   }

//   onClickDispatchModal(index) {
//     const user = this.getCurrentUser();
//     const dispatchObject = this.state.objects[index];
//     console.log("obb", dispatchObject);
//     const schema = this.getSchema("registrations");
//     dialog.fire({
//       html: (
//         <>
//           <EventDialog
//             schema={schema}
//             schemas={this.getSchemas()}
//             onCancel={() => dialog.close()}
//             dispatchObject={dispatchObject}
//             user={user}
//           />
//         </>
//       ),
//       footer: false,
//     });
//   }

//   render() {
//     const schema = this.getSchema(this.getCollectionName());
//     const { objects, count, progress, registrations } = this.state;
//     console.log("events", objects);
//     if (!schema) return <Progress />;
//     const user = this.getCurrentUser();
//     return (
//       <>
//         <NavBar />

//         <div className="overflow-auto">
//           <InfiniteScroll
//             className="h-100"
//             loadMore={this.loadMore.bind(this)}
//             hasMore={!progress && count > objects.length}
//           >
//             <div className="p-3 p-lg-4">
//               <div className="d-flex justify-content-between align-items-center">
//                 <h1 className="fw-bold text-capitalize">
//                   {schema.label || this.getCollectionName()}
//                 </h1>
//               </div>
//               <>
//                 <div className="container mt-4">
//                   <div className="row row-cols-1 row-cols-md-4 g-4">
//                     {objects.map((event, index) => (
//                       <div key={index} className="col">
//                         <div className="card h-100">
//                           <div className="g-0">
//                             <div
//                               className="col-md-12"
//                               style={{
//                                 height: "200px",
//                                 backgroundImage: `url(${event.picture})`,
//                                 backgroundSize: "cover",
//                                 backgroundPosition: "center",
//                                 backgroundRepeat: "no-repeat",
//                               }}
//                             />
//                             <div
//                               className="col-md-12"
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 justifyContent: "space-between",
//                                 height: "100%",
//                               }}
//                             >
//                               <div>
//                                 <div className="card-body">
//                                   <h6 className="fw-bold">Event Name:</h6>
//                                   <p className="card-title">
//                                     {event.eventName}
//                                   </p>
//                                   <h6 className="fw-bold">Location:</h6>
//                                   <p className="card-text">{event.location}</p>
//                                   <Button
//                                     style={{
//                                       backgroundColor: "#3A57A7",
//                                       color: "white",
//                                       width: "100%",
//                                       alignSelf: "center",
//                                       marginTop: "auto",
//                                     }}
//                                     onClick={() =>
//                                       this.onClickDispatchModal(index)
//                                     }
//                                   >
//                                     Register
//                                   </Button>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </>
//             </div>
//           </InfiniteScroll>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(EventPage);

import React from "react";
import withRouter from "../../withRouter";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import { Button, InfiniteScroll, Progress, dialog } from "nq-component";
import EventPresenter from "./EventPresenter";
import EventDialog from "./components/EventDialog";
import QRPage from "./components/QRPage";

class EventPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.state = {
      objects: [],
      selected: [],
      loading: true,
      total: 0,
      count: 0,
      report: [],
      registrations: [],
      member: [],
    };
    this.presenter = new EventPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
  }

  getCollectionName() {
    return "events";
  }

  setMembers(member) {
    this.setState({ member });
  }

  isRegistered(eventId) {
    console.log("eeee", eventId);
    const { member } = this.state;
    console.log("mmmmm", member);

    // console.log("isis", user);
    return member[0].event?.some((user) => user.id === eventId);
  }

  onClickDispatchModal(index) {
    const user = this.getCurrentUser();
    const dispatchObject = this.state.objects[index];
    const schema = this.getSchema("registrations");
    dialog.fire({
      html: (
        <>
          <EventDialog
            schema={schema}
            schemas={this.getSchemas()}
            onCancel={() => dialog.close()}
            dispatchObject={dispatchObject}
            user={user}
          />
        </>
      ),
      footer: false,
    });
  }
  onClickQRModal(id) {
    const user = this.getCurrentUser();
    // const qrObject = this.state.registrations[index];
    // console.log("getQR", qrObject);
    console.log("haha id", id);
    dialog.fire({
      html: (
        <div className="text-center">
          <QRPage qrObject={id} />
          <div style={{ padding: "10px" }}>
            <Button
              style={{
                backgroundColor: "#3A57A7",
                color: "white",
              }}
              onClick={() => dialog.close()}
            >
              Close
            </Button>
          </div>
        </div>
      ),
      footer: false,
    });
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, count, progress, registrations } = this.state;
    console.log("ob", objects);
    if (!schema) return <Progress />;

    const user = this.getCurrentUser();
    console.log("user", user);
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || this.getCollectionName()}
                </h1>
              </div>
              <div className="container mt-4">
                <div className="row row-cols-1 row-cols-md-4 g-4">
                  {objects.map((event, index) => (
                    <div key={index} className="col">
                      <div className="card h-100">
                        <div className="g-0">
                          <div
                            className="col-md-12"
                            style={{
                              height: "200px",
                              backgroundImage: `url(${event.picture})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              height: "100%",
                            }}
                          >
                            <div className="card-body">
                              <h5 className="card-title fw-bold">
                                {event.eventName}
                              </h5>
                              <p
                                className="card-text"
                                style={{ opacity: "0.5", fontSize: "14px" }}
                              >
                                {event.location}
                              </p>
                              <div className="row m-1">
                                <div
                                  className="col p-1 text-center"
                                  style={{
                                    backgroundColor: "#293d7d",
                                    color: "#FFFFFF",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p
                                    className="card-text fw-bold"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {new Date(
                                      event.eventDate
                                    ).toLocaleDateString("en-US")}
                                  </p>
                                </div>
                                <div className="col"></div>
                              </div>

                              {this.isRegistered(event.id) ? (
                                <>
                                  <Button
                                    style={{
                                      backgroundColor: "#3A57A7",
                                      color: "white",
                                      width: "100%",
                                      alignSelf: "center",
                                      marginTop: "auto",
                                    }}
                                    disabled={true}
                                  >
                                    Registered
                                  </Button>
                                  <Button
                                    className="btn mt-2"
                                    style={{
                                      backgroundColor: "black",
                                      color: "white",
                                      width: "100%",
                                      alignSelf: "center",
                                      marginTop: "auto",
                                    }}
                                    onClick={() =>
                                      //   this.navigateTo(`/qr-validation/${1}`)
                                      this.onClickQRModal(event.id)
                                    }
                                  >
                                    Show QR Code
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  style={{
                                    backgroundColor: "#3A57A7",
                                    color: "white",
                                    width: "100%",
                                    alignSelf: "center",
                                    marginTop: "auto",
                                  }}
                                  onClick={() =>
                                    this.onClickDispatchModal(index)
                                  }
                                >
                                  Register
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </InfiniteScroll>
        </div>
        {/* <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn btn-primary shadow-sm bg-primary"
            onClick={this.onClickAdd.bind(this)}
            style={{ width: "50px", height: "50px", borderRadius: "25px" }}
          >
            <i className="bi bi-plus-lg" />
          </Button>
        </div> */}
      </>
    );
  }
}

export default withRouter(EventPage);
