import React from "react";
import classNames from "../../classNames";

function InputFile({ className, value, onChange, saveFile, ...props }) {
  const [progress, setProgress] = React.useState(0);
  function _onChange(e) {
    const [file] = e.target.files;
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    saveFile
      .execute(file, options)
      .then((result) => {
        setProgress(0);
        onChange(result.url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <input
      className={classNames("form-control", className)}
      type="file"
      onChange={_onChange}
      {...props}
    />
  );
}

export default InputFile;
