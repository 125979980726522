import { dialog } from "nq-component";
import BaseListPresenter from "../../base/BaseListPresenter";
import { deleteObjectUseCase } from "../../usecases/object";

class EventListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.upsertUseCase = upsertUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.deleteSchemaUseCase = deleteSchemaUseCase;
  }

  componentDidUpdate(prevProps) {
    const query = this.view.getQuery();
    if (query.where && prevProps.query.where !== query.where) {
      this.reset();
      this.where = JSON.parse(query.where);
      this.getObjects();
    }
  }

  init() {
    this.limit = 20;
    this.where = {};
    this.current = 1;
    this.search = {};
    this.filter = {};
    this.include = ["all"];
    const query = this.view.getQuery();
    console.log("qqq", query);
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();

    if (query.where) {
      this.where = JSON.parse(query.where);
    }
  }

  onClickItem(index) {
    const object = this.objects[index];
    const collection = this.view.getCollectionName();
    this.view.navigateTo("/create-event/" + object.id);
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      console.log("get", this.objects);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }

  async onClickDelete(index) {
    dialog.close();
    const object = this.objects[index].id;
    await deleteObjectUseCase().execute("events", object);
    this.objects.splice(index, 1);
    this.view.setObjects(this.objects);
  }

  onClickAdd() {
    this.view.navigateTo("/create-event");
  }
}

export default EventListPresenter;
